<template>
  <div class="manage-aoi-wrapper">
    <div class="form-wrapper">
      <div class="header-wrapper flex-row">
          <div class="header-title">
            <span class="text">{{titleHeader}} {{($route.name === 'scene' || $route.name === 'behaviors' || $route.name === 'completion') && sceneName ? ' - ' + sceneName : ''}}</span>
            <span class="ml-auto header-actions">
              <span v-if="isViewingOrAdding" class="stepper" >
                <span class="stepper-circle mr-1" :class="{'active': $route.name === 'scene'}" v-on:click="goToTab(0)"></span>
                <span class="stepper-circle mr-2" :class="{'active': $route.name === 'completion'}" v-on:click="goToTab(1)"></span>
              </span>
              <b-icon-x class="header-cancel-button" v-on:click="$router.push('/')" variant="secondary"></b-icon-x>
            </span>
          </div>
      </div>
      <div class="inputs-wrapper">
        <router-view @onNameChanged="onNameChanged"></router-view>
      </div>
      <div class="flex-row save-button-wrapper no-wrap">
          <div class="flex-row no-wrap">
              <b-button class="transparent-button" variant="outline-primary" v-on:click="handleSupportClick()">
                  Get Support
              </b-button>
              <b-button v-if="$route.params.id && $route.params.id === 'new' && $route.name === 'scene'" class="back-button save-button transparent-action-button" style="margin-left: 15px; width: 200px;" variant="outline-primary" v-on:click="handleSettingsClick()">
                      <span>Advanced Settings</span>
              </b-button>
          </div>
          <div class="flex-row no-wrap flex-end">
              <b-button v-if="$route.path !== '/manageaoi'" style="margin-right: 15px" class="transparent-action-button save-button back-button" v-on:click="onBackClick" >
                  {{!$route.params.id || $route.params.id === 'new' ? '&lt; Back': 'Cancel'}}
              </b-button>
              <b-button v-if="$route.name !== 'completion' && $route.params.id && $route.params.id === 'new'"  class="transparent-button save-button" variant="outline-primary" v-on:click="handleNextClick()">Next &gt; </b-button>
              <b-button v-if="$route.name === 'completion' || ($route.params.id && $route.params.id !== 'new')" class="save-button finalize-button" variant="outline-primary" v-on:click="handleSaveClick()">{{$route.params.id === 'new' ? 'Run Analysis' : 'Re-Analyze'}}</b-button>
          </div>
      </div>
    </div>
      <a href="mailto:nash_support@bpcs.com" id="supportmailto" style="display:none"></a>

  </div>
</template>

<script>
import router from "../../router";
export default {
  name: "manageaoi",
  data() {
    return {
      isEdit: false,
      titleHeader: 'WORKSPACES',
      scene: null,
      behaviors: null,
      sceneName: "",
    };
  },
  mounted() {
    this.$store.commit('SHOW_HEADER', true);
    if(this.$router.currentRoute.query.pipelineId)
    this.$router.replace({query: {}});
  },
  computed: {
    isViewingOrAdding() {
      return this.$route.params.id && this.$route.params.id === 'new' && (this.$route.name === 'scene' || this.$route.name === 'completion');
    }
  },
  methods: {
    goToTab(index){
      switch (index) {
        case 0: {
          if(this.$route.name === 'completion')
            this.onBackClick();
          break;
        }
        case 1: {
          if(this.$route.name === 'scene')
            this.handleNextClick();
          break;
        }
      }
    },
    onBackClick(){
        switch (this.$route.name) {
            case 'completion':
                this.$router.push(`/manageaoi/pipeline/${this.$route.params.id}`);
                break;
            case 'behaviors':
                if(this.$route.params.id && this.$route.params.id !== 'new'){
                    this.$router.push("/");
                } else {
                    this.$router.push(`/manageaoi/pipeline/${this.$route.params.id}`);
                }
                break;
            case 'scene':
                if(this.$route.params.id && this.$route.params.id !== 'new') {
                    this.$router.push("/");
                } else {
                    this.$router.push("/manageaoi/pipelines");
                }
                break;
            default: this.$router.push("/manageaoi")
        }
    },
    onNameChanged(event){
        this.sceneName = event;
    },
    handleNextClick() {
        let id = this.$route.params.id ? this.$route.params.id : 'new';
        this.$router.push({path: `/manageaoi/pipeline/${id}/confirm`, params: router.currentRoute.params});
    },
    handleSettingsClick() {
        let id = this.$route.params.id ? this.$route.params.id : 'new';
        this.$router.push({path: `/manageaoi/pipeline/${id}/settings`, params: router.currentRoute.params});
    },
    handleSupportClick() {
        this.$children.forEach(childComponent => {
           if(childComponent.stopIntroVideo) {
               childComponent.stopIntroVideo();
           }
        });
          document.getElementById('supportmailto').click();
      },
    handleSaveClick() {

        const scene = this.$route.params.scene ? this.$route.params.scene.$model :this.$children[0].modelObject.scene;
        const behaviors = this.$route.params.behaviors ? [this.$route.params.behaviors.$model] : [this.$children[0].modelObject.behaviors];
        behaviors[0].oscillation = +behaviors[0].oscillation;
        behaviors[0].abnormality = +behaviors[0].abnormality;

        scene.smoothWindowLength = +behaviors[0].smoothWindowLength;
        scene.batchSize = +behaviors[0].batchSize;
        scene.name = scene.name.trim();

        delete behaviors[0].batchSize;
        delete behaviors[0].smoothWindowLength;

        this.$store.commit('SET_SELECTED_FILE', scene.filePath);

        const saveOptions = {scene, behaviors};
        saveOptions.name = scene.name;

        let saveAction = 'saveAnalysis';
        const isEdit = this.$route.params.id && !isNaN(this.$route.params.id);
        if (isEdit) {
            saveAction = 'updateAnalysis';
            saveOptions.id = this.$route.params.id;
        }

        this.$store.dispatch(saveAction, saveOptions)
            .then((data) => {
                if(data) {
                  this.$store.commit("SHOW_ALL_PANELS", false);
                  this.$store.dispatch('getPipelineConfig',  {id: isEdit ? saveOptions.id : data.id}).then(() => {
                      this.$store.commit('TOGGLE_PARTIAL_VIDEO_EVENT', false);
                      this.$router.push('/');
                      this.$store.dispatch('reloadAllVideoAnalytics', this.$store.state.videoPlayer.video.analyzeId);
                  });
                }
            })

    },
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300');

.manage-aoi-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/images/earth_background.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  .form-wrapper {
    .invalid-tooltip {
      position: inherit;
    }
    overflow: hidden;
    width: 75vw;
    min-width: 750px;
    .pic {
      width: 250px;
    }
    .header-wrapper {
      width: 100%;
      height: 100%;
      min-height: 60px;
      padding-top: 0;
      align-items: center;
      .header-title {
        flex-direction: row;
        display: flex;
        align-items: center;
        font-family: 'Roboto', Roboto-Medium ,sans-serif;
        letter-spacing: 2px;
        font-size: 16px;
        width: calc(100% - 48px);
        text-align: start;
        margin-left: 24px;
        margin-right: 24px;
        .text {
          max-height: 120px;
          word-break: break-all;
          overflow: hidden;
          padding: 20px 0px;
          margin-top: auto;
          text-overflow: ellipsis;
          -webkit-line-clamp: 4;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
      .header-actions {
        display: flex;
        align-items: center;
      }
    }

    .inputs-wrapper {
      background-color: rgb(33, 44, 56);
      padding: 0 25px;
      justify-content: center;
      display: flex;
      height: 450px;
      img {
        margin-bottom: 20px;
      }
    }

    .save-button-wrapper {
        .finalize-button {
            width: 180px !important;
        }
        .transparent-action-button{
            border: none;
            color: #0a78cc;
            background: transparent;
        }
        .back-button:focus{
            outline: none;
            box-shadow: none;
        }
        background-color: rgb(33, 44, 56);
        margin-top: 0!important;
        padding: 0 25px 15px;
     }

  }
}

input::placeholder {
  color: #ccc !important;
}

.stepper {
  display: flex;
  .stepper-circle {
    width: 12px;
    height: 12px;
    cursor: pointer;
    border: 1px solid grey;
    display: block;
    border-radius: 50px;
    &.active {
      background-color: #0a78cc;
      border-color: #0a78cc;
    }
  }
}

@media screen and (max-width: 900px){
  .manage-aoi-wrapper {
    .form-wrapper {
      overflow: hidden;
      width: 80vw;
      margin-top: 25px;

    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px){
  .manage-aoi-wrapper {
    .form-wrapper {
      overflow: hidden;
      width: 70vw;
      margin-top: 25px;
    }
  }
}
</style>
